// import Swiper from 'swiper';
import Swiper from '../../../node_modules/swiper/swiper-bundle';


function gallerySlider() {
  const rentSlider = document.querySelectorAll('.js-rent-slider');
  const textVideoSlider = document.querySelectorAll('.js-text-video-slider');

  if (rentSlider.length) {
    rentSlider.forEach((slider) => {
      const sliderContainer = slider.closest('.js-slider-container');
      const sliderPagination = sliderContainer.querySelector('.swiper-pagination');
      const arrowNext = sliderContainer.querySelector('.js-next');
      const arrowPrev = sliderContainer.querySelector('.js-prev');

      new Swiper(slider, {
        slidesPerView: 1,
        navigation: {
          nextEl: arrowNext,
          prevEl: arrowPrev
        },
        pagination: {
          el: sliderPagination,
          clickable: true,
          type: 'fraction',
        },
        cssMode: true,
      });
    });
  }

  if (textVideoSlider.length) {
    textVideoSlider.forEach((slider) => {
      const sliderContainer = slider.closest('.js-slider-container');
      const sliderPagination = sliderContainer.querySelector('.swiper-pagination');
      const arrowNext = sliderContainer.querySelector('.js-next');
      const arrowPrev = sliderContainer.querySelector('.js-prev');

      new Swiper(slider, {
        slidesPerView: 1,
        navigation: {
          nextEl: arrowNext,
          prevEl: arrowPrev
        },
        pagination: {
          el: sliderPagination,
          clickable: true,
          type: 'fraction',
        },
        cssMode: true,
      });
    });
  }
}

export default { gallerySlider };
