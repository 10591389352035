function sendForm() {
	const contactsForm = document.querySelector('.js-contact-us');

	if(!contactsForm) {
		return;
	}

	contactsForm.addEventListener("submit", (event) => {
    event.preventDefault();

    const name = contactsForm.querySelector('[name="name"]').value;
    const email = contactsForm.querySelector('[name="email"]').value;
    const phone = contactsForm.querySelector('[name="phone"]').value;
    const subject = contactsForm.querySelector('[name="subject"]').value;
    const message = contactsForm.querySelector('[name="textarea"]').value;
		const popupContactUs = document.getElementById('popup-contact-us');
		const popupClose = document.querySelectorAll('.popup-close')


		ajax({name, email, phone, subject, message}).then(() => {
			contactsForm.querySelector('[name="name"]').value = '';
			contactsForm.querySelector('[name="email"]').value = '';
			contactsForm.querySelector('[name="phone"]').value = '';
			contactsForm.querySelector('[name="subject"]').value = '';
			contactsForm.querySelector('[name="textarea"]').value = '';

			popupContactUs.classList.add('open');

			popupClose.forEach(close => {
				close.addEventListener('click', () => {
					popupContactUs.classList.toggle('open');
				})
			})
		});
  });

	const ajax = async(data) => {
		const params = new URLSearchParams();
		for (const [key, value] of Object.entries(data)) {
  		params.set(key, value);
		}

		const response = await fetch(`${wp_js.ajax}?action=consultation`, {
			method: 'POST',
			body: params,
		});

		const result = await response.json();

		console.log(result);

		if (result) {
      document
        .querySelector('.js-contact-us-answer')
        .textContent = 'Application successfully submitted.';
    } else {
    	document
        .querySelector('.js-contact-us-answer')
        .textContent = 'Something went wrong. Please reload this page and try again';
    }
	}
}

export default sendForm;