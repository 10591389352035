import Accordion from './modules/accordion.js';
import Modal from './modules/modalContainer.js';
import Tabs from './modules/tabs';
import sliders from './modules/sliders';
import toggleBurgerMenu from './modules/burger';
import sendForm from './modules/ajax';


document.addEventListener("DOMContentLoaded", function(event) {
	const pageClass = document.querySelector('main').classList.value;

	new Modal('.c-modal', '.l-modal-container');
	new Tabs('.js-text-video-tab');
	new Accordion('.js-accordion__item', '.js-accordion');
	toggleBurgerMenu();
	sliders.gallerySlider();

	sendForm();

	const buttonShowMoreFaq = document.querySelector('.button-more');

	if (buttonShowMoreFaq) {
		buttonShowMoreFaq.addEventListener('click', () => {
			const parent = buttonShowMoreFaq.parentElement;
			parent.classList.remove('short');
		})
	}
});