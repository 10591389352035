export default class tabs {
  constructor(selector) {
    this.selector = document.querySelectorAll(selector);
    if (this.selector.length) {
      this.init();
    }
  }

  closeTabs(tabs) {
    tabs.forEach(tab => {
      tab.classList.remove('is-active');
    });
  }

  init() {
    this.selector.forEach((selector, index) => {
      let tabNav = selector.querySelectorAll('[data-tab]');
      let tabContent = selector.querySelectorAll('.c-tab__content');

      tabNav.forEach(tab => {

        tab.addEventListener('mousemove', (event) => {
          let targetData = event.target.closest('[data-tab]').getAttribute('data-tab');

          this.closeTabs(tabNav);
          this.closeTabs(tabContent);

          event.target.closest('[data-tab]').classList.add('is-active');

          selector.querySelector(targetData).classList.add('is-active');
        });
      });
    }); 
  }
}

// export {tabs}; 