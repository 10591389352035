export default class Modal {
  constructor(modal, modalContainer) {
    this.body = document.body;
    this.html = document.documentElement;
    this.modal = document.querySelectorAll(modal);
    this.modalContainer = document.querySelector(modalContainer);
    this.modalContainerClass = modalContainer.replace('.', '');
    this.init();
  }

  init() {
    const dataSelector = document.querySelectorAll('[data-modal]');

    if (dataSelector.length) {
      dataSelector.forEach((dataItem) => {
        dataItem.addEventListener('click', () => {
          const attrID = dataItem.getAttribute('data-modal');
          this.modalController(attrID);
        });
      });
    }

    this.modalContainer.addEventListener('click', (event) => {
      if (event.target.classList.contains(this.modalContainerClass)) {
        this.bodyScroll('close');
        this.closeModal();
      }
    });
  }

  modalController(attrID) {
    const modalTarget = this.modalContainer.querySelector(attrID);

    if (attrID === 'close') {
      this.closeModal();
      this.bodyScroll('close');
    } else {
      if (attrID === '#mobile-menu') {
      }
      this.closeModal();
      this.openModal(modalTarget);
      this.bodyScroll();
    }
  }

  bodyScroll(target) {
    if (target === 'close') {
      this.body.classList.remove('is-overflow-hidden');
      this.html.classList.remove('is-overflow-hidden');
    } else {
      this.body.classList.add('is-overflow-hidden');
      this.html.classList.add('is-overflow-hidden');
    }
  }

  openModal(modalTarget) {
    if (!this.modalContainer.classList.contains('is-active')) {
      this.modalContainer.classList.add('is-active');
    }

    if (!modalTarget.classList.contains('is-active')) {
      modalTarget.classList.add('is-active');
    }
  }

  closeModal() {
    this.modal.forEach((item) => {
      if (item.classList.contains('is-active')) {
        item.classList.remove('is-active');
      }
    });

    if (this.modalContainer) {
      this.modalContainer.classList.remove('is-active');
    }
  }
}
